import Image from 'next/image'
import { makeStyles } from 'tss-react/mui'
import React from 'react'

import theme from 'src/styles/theme'

const useStyles = makeStyles()((defaultTheme, props) => {
  const { hover } = props

  return {
    defaultImage: {
      background: theme.palette.primary.light,
    },
    image: {
      transition: 'transform 0.5s ease-in-out',
      transform: hover ? 'scale(1.05)' : 'none',
      '&: hover': {
        transform: 'scale(1.05)',
        transition: 'transform 0.5s ease-in-out',
      },
    },
  }
})

const pattern = '/assets/patterns/manzanita/CypressGreen_CropLeft.png'

export default function ImageCard(props) {
  const { url, hover, title = '', alt = '' } = props
  const { classes } = useStyles({ hover })

  const [showDefaultImage, setShowDefaultImage] = React.useState(!url)

  React.useEffect(() => {
    setShowDefaultImage(!url)
  }, [url])

  return (
    <Image
      className={[
        classes.image,
        showDefaultImage ? classes.defaultImage : '',
      ].join(' ')}
      src={showDefaultImage ? pattern : url}
      alt={alt || 'Card image'}
      title={title}
      layout="fill"
      objectFit="cover"
      onError={(e) => setShowDefaultImage(true)}
    />
  )
}
